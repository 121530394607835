import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import React from 'react';

export function Footer() {

  return (
    <AppBar position='static' sx={{ top: 'auto', bottom: 0 }} style={{backgroundColor: '#F1BA00'}}>
      <Container maxWidth="md">
        <Toolbar>
          <Typography variant="body1" color="inherit">
            アプリについてのお問い合わせは<a href="https://zeq.co.jp/contact/" target="blank">こちら</a>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  )
}