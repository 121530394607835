import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export function OauthFlow(props) {
  
  const changeSubdomain = (e) => {
    props.setSubdomain(e.target.value);
  }

  return (
    <Box style={{ textAlign: 'left', marginBottom: '10px' }}>
      <form action="https://api.jtext.zeq.co.jp/oauth" method="post">
        <Box style={{ marginBottom: '10px' }} >
          <div>
            <h3>ご利用のZendesk環境のURL</h3>
          </div>
          <div style={{verticalAlign: 'center'}}>
            https://
            <TextField
              id="outlined-basic"
              onChange={changeSubdomain}
              inputProps={{ maxLength: 63 }}
              value={props.subdomain}
              name="subdomain"
              placeholder="subdomain"
              style={{ width: '150px' }}
              variant="standard"
              size="small"
            />
            .zendesk.com
          </div>
        </Box>
        <Button fullWidth variant="contained" type="submit" mt={2}>Zendeskにサインインする</Button>
      </form>
    </Box>
  )
}