import React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

export function RegistrationForm(props) {
  const [nextDisabled, setNextDisabled] = React.useState(true);
  const [agree, setAgree] = React.useState(false);
  const [betaAgree, setBetaAgree] = React.useState(false);
  const [companyName, setCompanyName] = React.useState('');
  const [personalName, setPersonalName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [plan, setPlan] = React.useState('');

  const plans = [
    'Team',
    'Growth',
    'Professional',
    'Enterprise',
    'Enterprise Plus',
    '上記以外のプラン'
  ];
  const requiredMessage = '必須項目です。';
  const emailFormatMessage = 'メールアドレスを入力してください。';
  const phoneFormatMessage = '電話番号をハイフンなしで入力してください。';
  const domainFormatMessage = '正しいドメイン形式で入力してください。';
  const freeEmailDomainMessage = '仕事で利用しているメールアドレスを入力ください。';
  const freeMainDomains = ['gmail.com', 'yahoo.co.jp'];
  const emailRegex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  const phoneRegex = /^(0{1}\d{9,10})$/;
  const domainRegex = /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  React.useEffect(() => {
    if(agree && betaAgree && companyName.length && personalName.length && email.length && phoneNumber.length && props.subdomain.length) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  }, [agree, betaAgree, companyName, personalName, email, phoneNumber, props.subdomain])

  const validation = () => {
    let messages = {}
    if (!companyName.length) {
      messages = {...messages, ...{ companyName: requiredMessage }}
    }
    if (!personalName.length) {
      messages = {...messages, ...{ personalName: requiredMessage }}
    }
    if (!email.length) {
      messages = {...messages, ...{ email: requiredMessage }}
    }
    if (!phoneNumber.length) {
      messages = {...messages, ...{ phoneNumber: requiredMessage }}
    }
    if (!props.subdomain.length) {
      messages = {...messages, ...{ subdomain: requiredMessage }}
    }
    if (!agree) {
      messages = {...messages, ...{ agree: requiredMessage }}
    }
    if (!betaAgree) {
      messages = {...messages, ...{ betaAgree: requiredMessage }}
    }
    if (email.length && !emailRegex.test(email)) {
      messages = {...messages, ...{ email: emailFormatMessage }}
    }
    if (email.length) {
      const emailDomain = email.split('@')[1];
      const freeMailDomainInclude = freeMainDomains.some(domain => domain.includes(emailDomain));
      if (freeMailDomainInclude) {
        messages = {...messages, ...{ email: freeEmailDomainMessage }}
      }
    }
    if (phoneNumber.length && !phoneRegex.test(phoneNumber)) {
      messages = {...messages, ...{ phoneNumber: phoneFormatMessage }}
    }
    if (props.subdomain.length && !domainRegex.test(`${props.subdomain}.zendesk.com`)) {
      messages = {...messages, ...{ subdomain: domainFormatMessage }}
    }

    setErrorMessages(messages)
    return Boolean(Object.keys(messages).length)
  }

  const closeBackdrop = () => {
    setBackdropOpen(false);
  };

  const changeAgree = (e) => {
    setAgree(e.target.checked);
  }

  const changeCompanyName = (e) => {
    setCompanyName(e.target.value);
  }

  const changePersonalName = (e) => {
    setPersonalName(e.target.value);
  }

  const changeEmail = (e) => {
    setEmail(e.target.value);
  }

  const changePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  }

  const changeSubdomain = (e) => {
    props.setSubdomain(e.target.value);
  }

  const changePlan = (e) => {
    setPlan(e.target.value);
  }

  const changeBetaAgree = (e) => {
    setBetaAgree(e.target.checked)
  }

  const handleNext = async () => {
    const inputs = {
      companyName,
      personalName,
      email,
      phoneNumber,
      agree,
      subdomain: props.subdomain,
      plan,
      betaAgree
    }
    const invalid = validation();
    if (invalid) { return }

    setBackdropOpen(true)
    const postResult = await axios.post('https://api.jtext.zeq.co.jp/registration', { inputs })
      .catch(() => { setBackdropOpen(false); })
    setBackdropOpen(false)
    if (postResult.data.statusCode === 200) {
      props.next();
    } else {
      if (postResult.data.errorMessage) {
        setErrorMessage(postResult.data.errorMessage);
        setShowError(postResult.data.errorMessage);
      }
      
      setErrorMessages(postResult.data.errors);
    }
  }

  return (
    <>
      { showError && <Alert severity="error">{errorMessage}</Alert> }
      <Box>
        <TextField 
          required 
          error={Boolean(errorMessages.companyName)}
          helperText={errorMessages.companyName}
          id="companyName"
          value={companyName}
          onChange={changeCompanyName} 
          label="会社名"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField 
          required
          error={Boolean(errorMessages.personalName)}
          helperText={errorMessages.personalName}
          id="personalName"
          value={personalName}
          onChange={changePersonalName}
          label="氏名"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField 
          required
          error={Boolean(errorMessages.email)}
          helperText={errorMessages.email || '仕事で利用しているメールアドレスを入力ください。gmail.comなどのフリーメールアドレスはお控えください'}
          id="email"
          value={email}
          onChange={changeEmail}
          label="仕事用のメールアドレス" 
          size="small"
          margin="dense"
          fullWidth
          placeholder="example@example.com"
        />
        <TextField
          required
          error={Boolean(errorMessages.phoneNumber)}
          helperText={errorMessages.phoneNumber || '日中連絡が取りやすい電話番号をハイフンなしで入力してください 例）0312345678'}
          id="phoneNumber"
          value={phoneNumber}
          onChange={changePhoneNumber}
          label="電話番号"
          size="small" 
          margin="dense"
          placeholder="0312345678"
          fullWidth
        />
        <TextField
          required
          error={Boolean(errorMessages.subdomain)}
          helperText={errorMessages.subdomain || 'アプリをインストールするZendesk環境の管理画面URL「***.zendesk.com」の***部分を入力ください。'}
          id="subdomain"
          value={props.subdomain}
          onChange={changeSubdomain}
          label="サブドメイン"
          size="small" 
          margin="dense"
          fullWidth
        />
        <FormControl fullWidth margin="dense" size="small">
          <InputLabel size="small" id="plan-label">プラン</InputLabel>
          <Select
            labelId="plan-label"
            id="plan"
            value={plan}
            label="プラン"
            onChange={changePlan}
          >
            {plans.map(plan => <MenuItem value={plan} key={plan}>{plan}</MenuItem>)}
          </Select>
          <FormHelperText>Zendesk SupportもしくはSuiteのプランを選択ください（任意）</FormHelperText>
        </FormControl>
        <FormControl
          required
          error={errorMessages.agree}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={agree} onChange={changeAgree} />}
              style={{fontSize: 12}}
              label={
                <Typography fontSize={12}>
                  Zendeskアプリケーション提供元である株式会社ZeQの<a href="https://zeq.co.jp/privacy/" target="blank">プライバシーポリシー</a>に同意し申し込みます
                </Typography>
                } 
              />
          </FormGroup>
          {errorMessages.agree && <FormHelperText>{errorMessages.agree}</FormHelperText> } 
        </FormControl>
        <FormControl
          required
          error={errorMessages.betaAgree}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={betaAgree} onChange={changeBetaAgree} />}
              style={{fontSize: 12}}
              label={
                <Typography fontSize={12}>
                  ベータ版サービスであることを理解し、以下の注意事項に同意し申し込みます
                </Typography>
                } 
              />
          </FormGroup>
          {errorMessages.betaAgree && <FormHelperText>{errorMessages.betaAgree}</FormHelperText> } 
        </FormControl>
        <FormHelperText>
          「J-テキスト」はベータ版サービスのため、何らかの不具合が発生することがあります。
          J-テキスト（ベータ版）の使用により生じた不具合、障害、および運用上のトラブルに対して、提供元である株式会社ZeQは一切責任を負いません。
        </FormHelperText>
        <FormHelperText>
          J-テキストにて無料で文字起こしできるのは月1,000分までです。
        </FormHelperText>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ mt: 1, mr: 1 }}
          disabled={nextDisabled}
        >
          次へ
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}