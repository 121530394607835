import React from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';
import { RegistrationStep } from './components/RegistrationStep';
import { Footer } from './components/Footer';

function App() {
  const query = new URLSearchParams(useLocation().search);
  const state = query.get('state');
  const renderComponent = () => {
    if (state === 'success') {
      return <RegistrationStep step={2} />
    }
    return <RegistrationStep step={0} />
  }
  return (
    <div className="App">
      <AppBar style={{ backgroundColor: "#F1BA00" }} position="sticky">
        <h2>Zendesk Talk文字起こしアプリ「J-テキスト」をインストールする</h2>
      </AppBar>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{marginTop: '30px'}}>
        <Grid item xs={12} sm={4}>
          { state === 'fatal' && <Alert severity="error">エラーが発生しました。</Alert> }
          { renderComponent() }
        </Grid> 
      </Grid>
      <Footer />
    </div>
  );
}

export default App;
