import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

export function InstallApplication() {
  const vertical = 'bottom';
  const horizontal = 'center';
  const [alertOpen, setAlertOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const download = async () => {
    try {
      const res  = await axios.get('https://api.jtext.zeq.co.jp/download');
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;base64,' + res.data);
      element.setAttribute('download', 'j-text-app.zip');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (e) {
      setAlertOpen(true)
    }
  }

  return (
    <Box style={{ textAlign: 'left' }}>
      <Button fullWidth variant="contained" onClick={download} startIcon={<DownloadIcon />} >Download</Button>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            ダウンロードに失敗しました。
          </Alert>
        </Snackbar>
      </Stack>
    </Box>
  )
}