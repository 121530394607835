import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { RegistrationForm } from './RegistrationForm';
import { InstallApplication } from './InstallApplication';
import { OauthFlow } from './OauthFlow';
import { useLocation } from 'react-router-dom';

const InstallDescription = () => {
  const query = new URLSearchParams(useLocation().search);
  return (
    <>
      <p>Zendesk管理画面で、ダウンロードしたzipファイルをプライベートアプリとしてアップロードします。</p>
      （<a target="blank" href={`https://${query.get('subdomain')}.zendesk.com/agent/admin/apps/manage`}>設定＞アプリ＞管理＞</a>「プライベートアプリのアップロード」）
    </>
  )
}

const steps = [
  {
    label: 'Step1: お申し込みフォーム',
    description: `下記項目についてお答えください。`,
  },
  {
    label: 'Step2: Zendeskでサインインする',
    description: `サインインしたエージェントの名前でチケットコメントが更新されます。`,
  },
  {
    label: 'Step3: Zendeskアプリをインストール',
    description: <InstallDescription />,
  },
];

export function RegistrationStep(props) {
  const sessionStorageKey = 'j-text-application-form-current-step';
  const [subdomain, setSubdomain] = React.useState('');
  let initialStep = 0;
  if (props.step === 0) {
    initialStep = Number(sessionStorage.getItem(sessionStorageKey))
  } else {
    initialStep = props.step
    sessionStorage.setItem(sessionStorageKey, initialStep);
  }

  const [activeStep, setActiveStep] = React.useState(initialStep);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const currentStep = prevActiveStep + 1
      sessionStorage.setItem(sessionStorageKey, currentStep);
      return currentStep;
    });
  };
  const getStepContent = (step) => {
    switch (step){
      case 0:
        return <RegistrationForm next={handleNext} subdomain={subdomain} setSubdomain={setSubdomain} />;
      case 1:
        return <OauthFlow subdomain={subdomain} setSubdomain={setSubdomain} />
      case 2:
        return <InstallApplication next={handleNext} />
      default:
        ''
    }
  }

  return (
    <Box sx={{ maxWidth: 500 }} style={{textAlign: 'left', marginLeft: '20px'}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography component={'span'} fontSize={12}>{step.description}</Typography>
              <Box sx={{ m: 2 }}>
                <div>
                  {getStepContent(index)}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}